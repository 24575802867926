<template>
  <div class="bottom-box" :class="wrapClass">
    <div class="left-wrap">
      <woot-button
        v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_EMOJI_ICON')"
        :title="$t('CONVERSATION.REPLYBOX.TIP_EMOJI_ICON')"
        icon="emoji"
        emoji="😊"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        @click="toggleEmojiPicker"
      />
      <file-upload
        ref="upload"
        v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
        :size="4096 * 4096"
        :accept="allowedFileTypes"
        :multiple="enableMultipleFileUpload"
        :drop="true"
        :drop-directory="false"
        :data="{
          direct_upload_url: '/rails/active_storage/direct_uploads',
          direct_upload: true,
        }"
        @input-file="onFileUpload"
      >
        <woot-button
          v-if="showAttachButton"
          class-names="button--upload"
          :title="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
          icon="attach"
          emoji="📎"
          color-scheme="secondary"
          variant="smooth"
          size="small"
        />
      </file-upload>
      <woot-button
        v-if="showAudioRecorderButton"
        v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_AUDIORECORDER_ICON')"
        :icon="!isRecordingAudio ? 'microphone' : 'microphone-off'"
        emoji="🎤"
        :color-scheme="!isRecordingAudio ? 'secondary' : 'alert'"
        variant="smooth"
        size="small"
        @click="toggleAudioRecorder"
      />

      <woot-button
        :key="'international-' + showCallButton"
        :title="$t(calltitle)"
        :icon="currentIcon"
        :tooltip="'For International Calls'"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        @click="startupClient"
      />
      <woot-button
        v-if="channelType === 'Channel::JioEva'"
        :key="'domestic-' + showDomesticCallButton"
        :title="$t(calltitle)"
        :icon="currentIcon"
        :tooltip="tooltipText"
        color-scheme="primary"
        variant="smooth"
        size="small"
        @click="initializeDomesticCall"
        @mouseover="tooltipText = 'Domestic Call'"
        @mouseleave="tooltipText = 'Indian Domestic Calls'"
        class="domestic-call"
      />
      <woot-button
        v-if="showEditorToggle"
        v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_FORMAT_ICON')"
        icon="quote"
        emoji="🖊️"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        @click="$emit('toggle-editor')"
      />

      <woot-button
        v-if="showAudioPlayStopButton"
        :icon="audioRecorderPlayStopIcon"
        emoji="🎤"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        @click="toggleAudioRecorderPlayPause"
      >
        <span>{{ recordingAudioDurationText }}</span>
      </woot-button>

      <woot-button
        v-if="showMessageSignatureButton"
        v-tooltip.top-end="signatureToggleTooltip"
        icon="signature"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        :title="signatureToggleTooltip"
        @click="toggleMessageSignature"
      />

      <woot-button
        v-if="showShopify"
        :title="shopifyTitle"
        :icon="shopifyIcon"
        :tooltip="'Fetch Orders'"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        @click="fetchOrders"
      />

      <woot-button
        v-if="hasWhatsappTemplates"
        v-tooltip.top-end="'Whatsapp Templates'"
        icon="whatsapp"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        :title="'Whatsapp Templates'"
        @click="$emit('selectWhatsappTemplate')"
      />
      <video-call-button
        v-if="(isAWebWidgetInbox || isAPIInbox) && !isOnPrivateNote"
        :conversation-id="conversationId"
      />
      <draggable-file
        :show="showDragmodal"
        :formatted="formattedTime"
        :hangup="CallHangup"
      />

      <woot-modal
        :show.sync="showFetchOrdersModal"
        :on-close="hideFetchOrdersModal"
      >
        <div class="p-4">
          <h2 class="text-xl font-bold mb-4">Shopify Orders</h2>
          <p v-if="isLoadingOrders" class="text-base">Fetching orders, please wait...</p>
          <div v-else>
             <AIAssistanceModal
                :ai-option="aiOption"
                @apply-text="insertText"
                @close="hideFetchOrdersModal"
                :orders="orders"
              />
            <!-- <ul v-if="orders.length > 0" class="space-y-4 list-none p-0">
              <li v-for="order in orders" :key="order.id" >
                <h3 class="text-lg font-semibold mb-2">Order #{{ order.id }}</h3>
                <p class="text-base mb-2">Total Price: {{ order.total_price }} {{ order.currency }}</p>
                <h4 class="text-base font-medium mb-2">Line Items:</h4>
                <ul class="list-disc pl-5">
                  <li v-for="item in order.line_items" :key="item.id" class="text-base mb-1">
                    <span class="font-medium">{{ item.name }}</span> - 
                    {{ item.price }} {{ item.currency }} x {{ item.quantity }}
                    (SKU: {{ item.sku }})
                  </li>
                </ul>
              </li>
            </ul> -->
            <!-- <p v-else class="text-base">No orders found.</p> -->
          </div>
        </div>
      </woot-modal>

      <AIAssistanceButton
        :conversation-id="conversationId"
        :is-private-note="isOnPrivateNote"
        :message="message"
        @replace-text="replaceText"
      />
      <transition name="modal-fade">
        <div
          v-show="$refs.upload && $refs.upload.dropActive"
          class="fixed top-0 bottom-0 left-0 right-0 z-20 flex flex-col items-center justify-center w-full h-full gap-2 text-slate-900 dark:text-slate-50 bg-modal-backdrop-light dark:bg-modal-backdrop-dark"
        >
          <fluent-icon icon="cloud-backup" size="40" />
          <h4 class="page-sub-title text-slate-900 dark:text-slate-50">
            {{ $t('CONVERSATION.REPLYBOX.DRAG_DROP') }}
          </h4>
        </div>
      </transition>
    </div>
    <div class="right-wrap">
      <woot-button
        size="small"
        :class-names="buttonClass"
        :is-disabled="isSendDisabled"
        @click="onSend"
      >
        {{ sendButtonText }}
      </woot-button>
    </div>
  </div>
</template>

<script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script type="text/javascript" src="twilio.min.js"></script>
<script>
import FileUpload from 'vue-upload-component';
import * as ActiveStorage from 'activestorage';
import { hasPressedAltAndAKey } from 'shared/helpers/KeyboardHelpers';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import twilioMixin from 'dashboard/mixins/twilioCall';
import inboxMixin from 'shared/mixins/inboxMixin';
import { FEATURE_FLAGS } from 'dashboard/featureFlags';
import {
  ALLOWED_FILE_TYPES,
  ALLOWED_FILE_TYPES_FOR_TWILIO_WHATSAPP,
} from 'shared/constants/messages';
import VideoCallButton from '../VideoCallButton';
import AIAssistanceButton from '../AIAssistanceButton.vue';
import { REPLY_EDITOR_MODES } from './constants';
import { mapGetters } from 'vuex';
import axios from 'axios';
import DraggableFile from './Draggable.vue';
import alertMixin from 'shared/mixins/alertMixin';
import AIAssistanceModal from '../AIAssistanceModal.vue';

let  token;
let device;
let call;

export default {
  name: 'ReplyBottomPanel',
  components: {
    FileUpload,
    VideoCallButton,
    AIAssistanceButton,
    DraggableFile,
    AIAssistanceModal
  },
  mixins: [
    eventListenerMixins,
    uiSettingsMixin,
    inboxMixin,
    twilioMixin,
    alertMixin,
  ],
  props: {
    mode: {
      type: String,
      default: REPLY_EDITOR_MODES.REPLY,
    },
    onSend: {
      type: Function,
      default: () => {},
    },
    sendButtonText: {
      type: String,
      default: '',
    },
    recordingAudioDurationText: {
      type: String,
      default: '',
    },
    inbox: {
      type: Object,
      default: () => ({}),
    },
    showFileUpload: {
      type: Boolean,
      default: false,
    },
    showAudioRecorder: {
      type: Boolean,
      default: false,
    },
    onFileUpload: {
      type: Function,
      default: () => {},
    },
    showEmojiPicker: {
      type: Boolean,
      default: false,
    },
    toggleEmojiPicker: {
      type: Function,
      default: () => {},
    },
    toggleAudioRecorder: {
      type: Function,
      default: () => {},
    },
    toggleAudioRecorderPlayPause: {
      type: Function,
      default: () => {},
    },
    isRecordingAudio: {
      type: Boolean,
      default: false,
    },
    recordingAudioState: {
      type: String,
      default: '',
    },
    isSendDisabled: {
      type: Boolean,
      default: false,
    },
    showEditorToggle: {
      type: Boolean,
      default: false,
    },
    isOnPrivateNote: {
      type: Boolean,
      default: false,
    },
    enableMultipleFileUpload: {
      type: Boolean,
      default: true,
    },
    hasWhatsappTemplates: {
      type: Boolean,
      default: false,
    },
    conversationId: {
      type: Number,
      required: true,
    },
    message: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showCallButton: true,
      showDomesticCallButton: true,
      currentIcon: 'call-start',
      calltitle: 'CONVERSATION.REPLYBOX.CALL',
      showDragmodal: false,
      timerRunning: false,
      seconds: 0,
      minutes: 0,
      hours: 0,
      tooltipText: 'Domestic Call',
      shopifyTitle: 'Shopify', 
      shopifyIcon: 'shopify',
      showFetchOrdersModal: false,
      orders: [], // To store the fetched orders
      isLoadingOrders: false, 
      showShopify: false,
      showShopifyId: false,
      aiOption: '',
      draftMessage: '',
      initialMessage: '',
    };
  },
  computed: {
    formattedTime() {
      return (
        this.pad(this.hours) +
        ':' +
        this.pad(this.minutes) +
        ':' +
        this.pad(this.seconds)
      );
    },
    ...mapGetters({
      currentChat: 'getSelectedChat',
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      baseUrl: 'getbaseUrl',
      currentUser: 'getCurrentUser',
      currentUserId: 'getCurrentUserID',
      shopifyHookExists: 'getShopifyHookExists',
    }),
    currentContact() {
      return this.$store.getters['contacts/getContact'](
        this.currentChat.meta.sender.id
      );
    },
    currentAgent() {
      const { id, name, email } = this.$store.getters.getCurrentUser;
      return { id, name, email };
    },
    isNote() {
      return this.mode === REPLY_EDITOR_MODES.NOTE;
    },
    wrapClass() {
      return {
        'is-note-mode': this.isNote,
      };
    },
    buttonClass() {
      return {
        warning: this.isNote,
      };
    },
    showAttachButton() {
      return this.showFileUpload || this.isNote;
    },
    hideAIAssistanceModal() {
      return true;
    },
    showAudioRecorderButton() {
      // Disable audio recorder for safari browser as recording is not supported
      const isSafari = /^((?!chrome|android|crios|fxios).)*safari/i.test(
        navigator.userAgent
      );

      return (
        this.isFeatureEnabledonAccount(
          this.accountId,
          FEATURE_FLAGS.VOICE_RECORDER
        ) &&
        this.showAudioRecorder &&
        !isSafari
      );
    },
    showAudioPlayStopButton() {
      return this.showAudioRecorder && this.isRecordingAudio;
    },
    allowedFileTypes() {
      if (this.isATwilioWhatsAppChannel) {
        return ALLOWED_FILE_TYPES_FOR_TWILIO_WHATSAPP;
      }
      return ALLOWED_FILE_TYPES;
    },
    audioRecorderPlayStopIcon() {
      switch (this.recordingAudioState) {
        // playing paused recording stopped inactive destroyed
        case 'playing':
          return 'microphone-pause';
        case 'paused':
          return 'microphone-play';
        case 'stopped':
          return 'microphone-play';
        default:
          return 'microphone-stop';
      }
    },
    showMessageSignatureButton() {
      return !this.isOnPrivateNote && this.isAnEmailChannel;
    },
    sendWithSignature() {
      const { send_with_signature: isEnabled } = this.uiSettings;
      return isEnabled;
    },
    signatureToggleTooltip() {
      return this.sendWithSignature
        ? this.$t('CONVERSATION.FOOTER.DISABLE_SIGN_TOOLTIP')
        : this.$t('CONVERSATION.FOOTER.ENABLE_SIGN_TOOLTIP');
    },
  },
  mounted() {
    ActiveStorage.start();
    this.check_hook();
    this.initialMessage = this.draftMessage;
    console.log("Agent details current:", this.currentAgent.id);

    this.fetchCurrentAssignee();
  },
  methods: {
    handleKeyEvents(e) {
      if (hasPressedAltAndAKey(e)) {
        this.$refs.upload.$children[1].$el.click();
      }
    },
    toggleMessageSignature() {
      this.updateUISettings({
        send_with_signature: !this.sendWithSignature,
      });
    },
    replaceText(text) {
      this.$emit('replace-text', text);
    },

    // Outgoing Call
    async updateUIAcceptedOutgoingCall() {
      this.startTimer();
    },

    async updateUIDisconnectedOutgoingCall() {
      alert('Call Ended');
      this.showDragmodal = false;
      this.resetTimer();
      console.log('::::::::::::Call has ended::::::::::');
    },

    async makeOutgoingCall() {
      try {
        const contact = this.currentContact;
        console.log('Contact', contact);
        let inbox_id = contact.contact_inboxes[0].inbox.id;
        var params = {
          To: contact.phone_number,
          account_id: this.accountId,
          contact_id: contact.id,
          inbox_id: inbox_id,
          userId: this.currentUserId,
        };
        if (device) {
          console.log(`Attempting to call ${params.To} ...`);
          call = await device.connect({ params });
          call.on('accept', this.updateUIAcceptedOutgoingCall);
          call.on('disconnect', this.updateUIDisconnectedOutgoingCall);
          call.on('cancel', this.updateUIDisconnectedOutgoingCall);
        } else {
          console.log('Unable to make call.');
        }
      } catch (error) {
        console.error(
          'Error making outgoing call:-------------------------',
          error
        );
      }
    },

    async CallHangup() {
      console.log('Call Disconnected');
      call.disconnect();
      this.$store.dispatch('contacts/toggleholdCallAvailableStatusFalse');
    },

    async getAudioDevices() {
      console.log('Inside getAudioDevices::::::::::::::::::::');
      await navigator.mediaDevices.getUserMedia({ audio: true });
    },

    addDeviceListeners(device) {
      device.on('registered', function () {
        console.log('Twilio.Device Ready to make and receive calls!');
      });
      device.on('error', function (error) {
        console.log('Twilio.Device Error: ' + error.message);
      });
    },

    async intitializeDevice() {
      console.log('Initializing device');
      device = new Twilio.Device(token, {
        logLevel: 1,
      });
      this.addDeviceListeners(device);
      device.register();
    },

    async fetchCurrentAssignee() {
      try {
        const response = await axios.get(
          `/api/v1/accounts/${this.accountId}/conversations/${this.conversationId}/messages/`,
          {
            headers: {
              'Content-Type': 'application/json',
              'api-access-token': this.currentUser.access_token,
            },
          }
        );

        const assignee = response.data.meta.assignee;

        if (assignee && assignee.id) {
          console.log("Fetched Assignee ID:", assignee.id);
          return assignee.id; // Return only the ID
        } else {
          console.error("No assignee found in response.");
          return null; // Return null if no ID is found
        }
      } catch (error) {
        console.error("Error fetching current assignee:", error);
        return null; // Return null in case of error
      }
    },

    async initializeDomesticCall() {
      try {
        const assigneeId = await this.fetchCurrentAssignee();

        if (!assigneeId) {
          console.error("Cannot proceed without a valid assignee ID.");
          alert("Failed to initialize the call due to missing assignee information.");
          return;
        }

        const contact = this.currentContact;
        console.log('Contact:', contact);
        const inbox_id = contact.contact_inboxes[0].inbox.id;
        const url = `${this.baseUrl}/domestic_calls/create_call`;
        const body = {
          phone_number: contact.phone_number,
          account_id: this.accountId,
          inbox_id: inbox_id,
          agent_id: assigneeId // Use the assignee ID here
        };

        const response = await axios.post(url, body);
        alert(response.data.message);
      } catch (error) {
        console.error("Error initializing domestic call:", error);
      }
    },

    async startupClient() {
      console.log('Requesting Access Token...');
      const contact = this.currentContact;
      console.log('contact::::', contact);
      let data; // Declare data variable outside the try block
      try {
        this.$store.dispatch('contacts/toggleholdCallAvailableStatusTrue');
        this.showDragmodal = !this.showDragmodal;
        this.getAudioDevices();
        console.log('Contact in yourMethod:', contact);
        console.log('User Id', this.currentUserId);
        // Remove redeclaration of data variable here
        const response = await axios.get(`${this.baseUrl}/twilio/token`, {
          params: {
            To: contact.phone_number,
            account_id: this.accountId,
            user_id: this.currentUserId,
          },
        });
        data = response.data;

        token = data.token;
        console.log('got token' + token);
        await this.intitializeDevice();
        this.makeOutgoingCall();
      } catch (err) {
        this.showAlert(data.message);
        this.showDragmodal = !this.showDragmodal;
        console.log(
          'An error occurred. See your browser console for more information.'
        );
      }
    },

    async fetchOrders() {
      this.showFetchOrdersModal = true;
      this.isLoadingOrders = true;
      this.orders = []; // Clear previous orders
      try {
        const response = await axios.post(
          `/api/v1/accounts/${this.accountId}/integrations/hooks/${this.showShopifyId}/process_event`,
          {
            event: {
              data: {
                conversation_display_id:  this.conversationId,
                name: "reply_suggestion"
              },
              id: this.showShopifyId,
              account_id: this.account_id,
            }
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'api-access-token': this.currentUser.access_token,
            },
          }
        );
        // Process orders one by one
        // for (const order of response.data.orders) {
        //   const processedOrder = {
        //     id: order.id,
        //     total_price: order.line_items.reduce((sum, item) => sum + parseFloat(item.price), 0).toFixed(2),
        //     currency: order.line_items[0]?.price_set?.shop_money?.currency_code || 'Unknown',
        //     line_items: order.line_items.map(item => ({
        //       id: item.id,
        //       name: item.name,
        //       price: item.price,
        //       sku: item.sku,
        //       quantity: item.quantity,
        //       currency: item.price_set?.shop_money?.currency_code || 'Unknown',
        //     })),
        //   };
        //   this.orders.push(processedOrder);
        //   // Force Vue to update the view after each order is added
        //   await this.$nextTick();
        // }

        if(response.data.message){
          this.orders = response.data.message;
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
        this.showAlert('Failed to fetch orders. Please try again.');
      } finally {
        this.isLoadingOrders = false;
      }
    },
    insertText(message) {
      this.$emit('replace-text', message);
    },
    async hideFetchOrdersModal() {
      // Hide the modal when closed
      this.showFetchOrdersModal = false;
    },
    async check_hook() {
      try {
        const response = await axios.get(
          `/api/v1/accounts/${this.accountId}/check_shopify_hook`,
          {
            headers: {
              'Content-Type': 'application/json',
              'api-access-token': this.currentUser.access_token
            },
          }
        );
        console.log(response);
        this.showShopify = response.data.hook_exists;
        if(this.showShopify){
          this.showShopifyId=response.data.hook.id;
        }
      } catch (error) {
        console.error('Error checking Shopify hook:', error);
        this.showAlert('Failed to check Shopify hook. Please try again.');
      }
    },

    startTimer() {
      this.timerRunning = true;
      this.timer = setInterval(this.updateTimer, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
      console.log('Stop timer', this.timer);
      this.timerRunning = false;
    },
    updateTimer() {
      console.log('UpdateTimer:::::::');
      this.seconds++;
      if (this.seconds === 60) {
        this.seconds = 0;
        this.minutes++;
        if (this.minutes === 60) {
          this.minutes = 0;
          this.hours++;
        }
      }
    },
    pad(value) {
      return value < 10 ? '0' + value : value;
    },
    resetTimer() {
      this.stopTimer();
      this.seconds = 0;
      this.minutes = 0;
      this.hours = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.domestic-call {
  color: white;
  background-color: blue;
}
.bottom-box {
  @apply flex justify-between py-3 px-4;

  &.is-note-mode {
    @apply bg-yellow-100 dark:bg-yellow-800;
  }
}

.left-wrap {
  @apply items-center flex gap-2;
}

.right-wrap {
  @apply flex;
}

::v-deep .file-uploads {
  label {
    @apply cursor-pointer;
  }

  &:hover button {
    @apply dark:bg-slate-800 bg-slate-100;
  }
}

.icon {
  @apply text-[5rem];
}
</style>