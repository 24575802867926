
<template>
  <div class="flex-1 overflow-auto p-4">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-top"
      icon="add-circle"
      @click="openAddPopup()"
    >
      {{ $t('AGENT_MGMT.HEADER_BTN_TXT') }}
    </woot-button>

    <!-- List Agents -->
    <div class="flex flex-row gap-4">
      <div class="w-[60%]">
        <woot-loading-state
          v-if="uiFlags.isFetching"
          :message="$t('AGENT_MGMT.LOADING')"
        />
        <div v-else>
          <p v-if="!agentList.length">
            {{ $t('AGENT_MGMT.LIST.404') }}
          </p>
          <table v-else class="woot-table">
            <tbody>
              <tr v-for="(agent, index) in agentList" :key="agent.email">
                <!-- Gravtar Image -->
                <td>
                  <thumbnail
                    :src="agent.thumbnail"
                    class="columns"
                    :username="agent.name"
                    size="40px"
                    :status="agent.availability_status"
                  />
                </td>
                <!-- Agent Name + Email -->
                <td>
                  <span class="agent-name">
                    {{ agent.name }}
                  </span>
                  <span>{{ agent.email }}</span>
                </td>
                <!-- Agent Role + Verification Status -->
                <td>
                  <span class="agent-name">
                    {{
                      $t(`AGENT_MGMT.AGENT_TYPES.${agent.role.toUpperCase()}`)
                    }}
                  </span>
                  <span v-if="agent.confirmed">
                    {{ $t('AGENT_MGMT.LIST.VERIFIED') }}
                  </span>
                  <span v-if="!agent.confirmed">
                    {{ $t('AGENT_MGMT.LIST.VERIFICATION_PENDING') }}
                  </span>
                </td>
                <td>
                  <span class="agent-name">Twilio Number</span>
                  <span>{{ agent.twilio_number }}</span>
                </td>
                <td>
                  <button
                    v-if="currentRole === 'administrator'"
                    @click="onEdit(index)"
                    class="Edit-Button"
                  >
                    Edit
                  </button>
                </td>

                <!-- Actions -->
                <td>
                  <div class="button-wrapper">
                    <woot-button
                      v-if="showEditAction(agent)"
                      v-tooltip.top="$t('AGENT_MGMT.EDIT.BUTTON_TEXT')"
                      variant="smooth"
                      size="tiny"
                      color-scheme="secondary"
                      icon="edit"
                      class-names="grey-btn"
                      @click="openEditPopup(agent)"
                    />
                    <woot-button
                      v-if="showDeleteAction(agent)"
                      v-tooltip.top="$t('AGENT_MGMT.DELETE.BUTTON_TEXT')"
                      variant="smooth"
                      color-scheme="alert"
                      size="tiny"
                      icon="dismiss-circle"
                      class-names="grey-btn"
                      :is-loading="loading[agent.id]"
                      @click="openDeletePopup(agent, index)"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="w-[34%]">
        <span
          v-dompurify-html="
            useInstallationName(
              $t('AGENT_MGMT.SIDEBAR_TXT'),
              globalConfig.installationName
            )
          "
        />
      </div>
    </div>
    <!-- Add Agent -->
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-agent :on-close="hideAddPopup" />
    </woot-modal>
    <!-- Edit Agent -->
    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-agent
        v-if="showEditPopup"
        :id="currentAgent.id"
        :name="currentAgent.name"
        :type="currentAgent.role"
        :email="currentAgent.email"
        :availability="currentAgent.availability_status"
        :on-close="hideEditPopup"
      />
    </woot-modal>
    <!-- Delete Agent -->
    <woot-delete-modal
      :show.sync="showDeletePopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('AGENT_MGMT.DELETE.CONFIRM.TITLE')"
      :message="$t('AGENT_MGMT.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />

    <woot-modal :show.sync="showEditProfilePopup" :on-close="onClose">
      <woot-modal-header
        :header-title="'Edit Agents Details'"
        :header-content="'You can add people who will be able to handle support for your inboxes'"
      />

      <form
        class="flex flex-col w-full items-start"
        @submit.prevent="handleEdit"
      >
        <div class="w-full">
          <label>
            Agent Name
            <input
              v-model="agentName"
              type="text"
              placeholder="Please Enter the Name of the Agent"
              disabled
            />
          </label>
        </div>

        <div class="w-full">
          <label>
            Email Address
            <input
              v-model="agentEmail"
              type="text"
              placeholder="Please Enter the E-mail of the Agent"
              disabled
            />
          </label>
        </div>
        <div class="w-full">
           <label>
            Twilio Number
            <select v-model="agentMobileNumber">
              <option
                v-for="twilio in twilioNumbers"
                :key="twilio.number"
                :value="twilio.number"
                :selected="twilio.number === agentMobileNumber"
              >
                {{ twilio.number }} ( {{twilio.official_name}} )
              </option>
            </select>
          </label>
        </div>

          <!-- Agent Phone Number -->
        <div class="w-full">
          <label>
            Phone Number
            <input
              v-model="agentPhoneNumber"
              type="text"
              placeholder="Enter the phone number of the agent"
            />
          </label>
        </div>

        <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
          <div class="w-full">
            <woot-submit-button :button-text="'Save'" />
            <button class="button clear" @click="onClose">Cancel</button>
          </div>
        </div>
      </form>
    </woot-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import Thumbnail from '../../../../components/widgets/Thumbnail.vue';
import AddAgent from './AddAgent.vue';
import EditAgent from './EditAgent.vue';

export default {
  components: {
    AddAgent,
    EditAgent,
    Thumbnail,
  },
  mixins: [globalConfigMixin],
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showDeletePopup: false,
      showEditPopup: false,
      showEditProfilePopup: false,
      agentName: '',
      agentEmail: '',
      agentPhoneNumber: '',
      agentNumber: '',
      agentMobileNumber: '',
      agentAPI: {
        message: '',
      },
      currentAgent: {},
      selectedNumber: '',
      twilioNumbers: [],
      selectedTwilioNumber: '',
      agentId: '',
    };
  },
  computed: {
    ...mapGetters({
      agentList: 'agents/getAgents',
      uiFlags: 'agents/getUIFlags',
      currentUserId: 'getCurrentUserID',
      globalConfig: 'globalConfig/get',
      baseUrl: 'getbaseUrl',
      accountId: 'getCurrentAccountId',
      currentRole: 'getCurrentRole',
    }),
    deleteConfirmText() {
      return `${this.$t('AGENT_MGMT.DELETE.CONFIRM.YES')} ${
        this.currentAgent.name
      }`;
    },
    deleteRejectText() {
      return `${this.$t('AGENT_MGMT.DELETE.CONFIRM.NO')} ${
        this.currentAgent.name
      }`;
    },
    deleteMessage() {
      return ` ${this.currentAgent.name}?`;
    },
  },
  mounted() {
    this.$store.dispatch('agents/get');
    this.gettwilioNumber();
  },
  methods: {
    showEditAction(agent) {
      return this.currentUserId !== agent.id;
    },
    showDeleteAction(agent) {
      if (this.currentUserId === agent.id) {
        return false;
      }

      if (!agent.confirmed) {
        return true;
      }

      if (agent.role === 'administrator') {
        return this.verifiedAdministrators().length !== 1;
      }
      return true;
    },
    verifiedAdministrators() {
      return this.agentList.filter(
        agent => agent.role === 'administrator' && agent.confirmed
      );
    },
    // Edit Function
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },

    // Edit Function
    openEditPopup(agent) {
      this.showEditPopup = true;
      this.currentAgent = agent;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },

    // Delete Function
    openDeletePopup(agent) {
      this.showDeletePopup = true;
      this.currentAgent = agent;
    },
    closeDeletePopup() {
      this.showDeletePopup = false;
    },
    confirmDeletion() {
      this.loading[this.currentAgent.id] = true;
      this.closeDeletePopup();
      this.deleteAgent(this.currentAgent.id);
    },
    async deleteAgent(id) {
      try {
        await this.$store.dispatch('agents/delete', id);
        this.showAlert(this.$t('AGENT_MGMT.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.DELETE.API.ERROR_MESSAGE'));
      }
    },
    // Show SnackBar
    showAlert(message) {
      // Reset loading, current selected agent
      this.loading[this.currentAgent.id] = false;
      this.currentAgent = {};
      // Show message
      this.agentAPI.message = message;
      bus.$emit('newToastMessage', message);
    },

    async handleEdit() {
      console.log('Edit');
      console.log('Agent Name:', this.agentName);
      console.log('Agent Email:', this.agentEmail);
      console.log('Selected Twilio Number:', this.selectedNumber);

      const payload = {
        name: this.agentName,
        email: this.agentEmail,
        phone_number: this.agentPhoneNumber, // Include phone number
      };

      // Dispatch the Vuex action to update the agent
      await this.$store.dispatch('agents/update', {
        id: this.agentId,
        ...payload,
      });

      // Clear form fields after submission if needed
      this.selectedTwilioNumber = this.selectedNumber;
      this.agentName = '';
      this.agentEmail = '';
      this.selectedNumber = '';
      this.showEditProfilePopup = false;
      try {
        console.log('Agent Name:', this.agentName);
        console.log('Agent Email:', this.agentEmail);
        console.log('Selected Twilio Number:', this.selectedNumber);
        // Clear form fields after submission if needed
        this.selectedTwilioNumber = this.selectedNumber;
        const response = await axios.post(
          `${this.baseUrl}/twilio/assign_number`,
          {
            account_id: this.accountId,
            userId: this.agentId,
            assignednumber: this.agentMobileNumber,
            phone_number: this.agentPhoneNumber, 
          }
        );
        console.log('response from Server:-', response);
        const value= await this.$store.dispatch('agents/get');
        this.selectedNumber = this.agentMobileNumber;
        this.agentName = '';
        this.agentEmail = '';
        this.agentMobileNumber = '';
        this.showEditProfilePopup = false;
      } catch (err) {
        console.log('Error:-', err);
      }
    },
    onClose() {
      this.showEditProfilePopup = false;
    },
    async onEdit(index) {
      try {
        this.showEditProfilePopup = true;
        console.log('Selected Number:', this.selectedNumber);

        // Access the data of the agent at the specified index
        const agent = this.agentList[index];
        console.log('Selected Number  hjhjhj:', agent.twilio_number);
        if (agent) {
          this.agentName = agent.name;
          this.agentEmail = agent.email;
          this.agentMobileNumber = agent.twilio_number;
          this.agentPhoneNumber = agent.phone_number || '';
          // You can also set other properties if needed
        }
        this.agentId = this.agentList[index].id;
        const response = await axios.get(
          `${this.baseUrl}/twilio/get_credentials`,
          {
            params: {
              account_id: this.accountId,
            },
          }
        );

        // Update data values with response data
        console.log('Response from server:', response);
        const responseData = response.data.data;
        const twilioNumbers = responseData.twilioNumbers;
        this.twilioNumbers = twilioNumbers;
        // const savedData = localStorage.getItem('inputBoxes');
        console.log('Twilio Credential:-', twilioNumbers, this.twilioNumbers);
        // Handle response if needed
        console.log('Response from server:', response);
      } catch (error) {
        console.error('Error getting data from server:', error);
      }
    },
    async gettwilioNumber() {
      console.log('twilio Number:- ', this.selectedTwilioNumber);
    },
  },
};
</script>

<style>
.Edit-Button {
  margin: 5px 0px;
  cursor: pointer;
  background-color: #44ce4b;
  color: #fff;
  padding: 10px;
  border-radius: 10%;
}
</style>inde
