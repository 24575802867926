<template>
  <div class="w-full">
    <form class="mx-0 flex flex-wrap" @submit.prevent="handleSubmit">
      <div
        class="flex-row border-b border-slate-25 dark:border-slate-800 p-0.5"
      >
        <div class="w-[75%]">
          <whatsapp-form v-if="isDataReady" :formData="formobjectdata"  @update="updateFormData" :disablename="true" :disableCategory="true"/>
          <div class="flex-grow-0 flex-shrink-0 flex-[50%]">
            <!-- FOR HEADER BOX -->
            <input type="checkbox" v-model="headerCheck" />
            <label class="text-base font-bold pb-2">
              {{ $t('WHATSAPP_TEMPLATES.FORM.HEADER.LABEL') }}
              <span class="text-xs pl-3">
                {{ $t('WHATSAPP_TEMPLATES.FORM.HEADER.PLACEHOLDER') }}
              </span>
            </label>
            <p>
              {{ $t('WHATSAPP_TEMPLATES.FORM.DESCRIPTION.DESC') }}
            </p>
            <div v-if="headerCheck" class="mb-6">
              <div class="flex gap-4">
                <select id="dropdown" v-model="format" class="w-[20%] mb-0">
                  <option value="text">
                    {{
                      $t('WHATSAPP_TEMPLATES.FORM.SELECTOPTION.SAMPLE1.LABEL')
                    }}
                  </option>
                  <option value="image">
                    {{
                      $t('WHATSAPP_TEMPLATES.FORM.SELECTOPTION.SAMPLE2.LABEL')
                    }}
                  </option>
                  <option value="video">
                    {{
                      $t('WHATSAPP_TEMPLATES.FORM.SELECTOPTION.SAMPLE3.LABEL')
                    }}
                  </option>
                  <option value="document">
                    {{
                      $t('WHATSAPP_TEMPLATES.FORM.SELECTOPTION.SAMPLE4.LABEL')
                    }}
                  </option>
                </select>
                <div class="relative w-full">
                  <input
                    v-model="header"
                    type="text"
                    @input="validateCharacterLimit"
                  />
                  <!-- <p v-if="restrictionHeader" class="required-asterisk">
                    *No two vaiable at continous place
                  </p> -->
                  <span class="text-xs absolute right-2 top-4 text-gray-500">
                    {{ header.length }}/{{ maxCharacterLimit }}
                  </span>
                </div>
              </div>
              <div v-if="format === 'text'" class="character-limit-error">
                {{ characterLimitError }}
              </div>
              <div class="flex float-right cursor-pointer">
                <div
                  :disabled="headerCount >= 1"
                  @click.prevent="headerInputBox"
                >
                  {{ $t('WHATSAPP_TEMPLATES.FORM.ADDVARIABLE.DESC') }}
                </div>
              </div>
            </div>

            <label v-if="['image', 'video', 'document'].includes(format)">
              <div class="upload-image-btn">
                <input
                  v-if="format === 'image'"
                  type="file"
                  accept="image/*"
                  @change="onFileChange"
                />
                <input
                  v-else-if="format === 'video'"
                  type="file"
                  accept="video/*"
                  @change="onFileChange"
                />
                <input
                  v-else-if="format === 'document'"
                  type="file"
                  accept=".pdf"
                  @change="onFileChange"
                />
                <div class="flex gap-2">
                  <button class="button buttonDisplay">
                    {{ $t('WHATSAPP_TEMPLATES.FORM.ADDSAMPLE.DESC') }}
                  </button>
                  <span class="text-sm pt-2">{{ fileName }}</span>
                </div>
              </div>
            </label>
            <div class="body-content">
              <div v-if="isLoading" class="template--loader p-4">
                  <spinner size="large" color-scheme="primary" />
                  <span class="text-white">Uploading</span>
              </div>
              <div v-if="headerCheck && imagePreviewUrl" class="p-4">
                <img :src="imagePreviewUrl" alt="Image Preview" class="w-full" />
              </div>
              <div v-if="headerCheck && videoPreviewUrl" class="p-4">
                <video controls :src="videoPreviewUrl" class="w-full" />
              </div>
              <div v-if="headerCheck && documentPreviewUrl" class="p-4">
                <iframe
                  :src="documentPreviewUrl"
                  class="w-full"
                  height="500"
                  frameborder="0"
                ></iframe>
              </div>


              <div v-if="headerCheck && header_handle " class="p-4">
                <video v-if="isVideo && !videoPreviewUrl" controls width="600">
                  <source :src="header_handle" type="video/mp4">
                  Your browser does not support the video tag.
                </video>

                <!-- Render image if file type is image -->
                <img v-else-if="isImage && !imagePreviewUrl" :src="header_handle" alt="Image Preview" width="600" height="500" />

                <iframe
                  v-if="isDocument && !documentPreviewUrl"
                  :src="header_handle"
                  class="w-full"
                  height="500"
                  frameborder="0"
                ></iframe>

              </div>
              <div v-if="showheader && headerCheck" class="p-4">
                <!--<h6>{{ $t('WHATSAPP_TEMPLATES.FORM.HEADEROPTIONAL.DESC') }}</h6>
                <p>
                  {{ $t('WHATSAPP_TEMPLATES.FORM.HEADEROPTBODY.DESC') }}
                </p> -->
                <div
                  v-for="index in headerCount"
                  :key="index"
                  class="flex p-3 gap-2"
                >
                  <label>{{
                    $t(`WHATSAPP_TEMPLATES.FORM.VARIABLES.SAMPLE${index}.LABEL`)
                  }}</label>
                  <input
                    v-model="header_text[index - 1]"
                    type="text"
                    class="w-[95%]"
                  />
                </div>
              </div>
            </div>
            <!-- FOR BODY BOX -->
            <label class="text-base font-bold pb-2">
              {{ $t('WHATSAPP_TEMPLATES.FORM.BODY.LABEL') }}
            </label>
            <P>
              {{ $t('WHATSAPP_TEMPLATES.FORM.DESC.LABEL') }}
            </P>
            <div class="mb-6">
              <woot-message-editor
                id="message-signature-input"
                v-model="body"
                class="message-editor"
                :is-format-mode="true"
                :min-height="4"
                @input="saveInputBoxData"
              />
              <p v-if="restrictionBody" class="required-asterisk">
                *No two vaiable at continous place
              </p>
              <!-- <div v-if="messageVisible" class="warning-message p-5">
                <li>
                  <span class="text-xl">&#9888;</span>
                  {{ $t('WHATSAPP_TEMPLATES.FORM.INFORMATION.LABEL') }}
                  <router-link to="">
                    {{ $t('WHATSAPP_TEMPLATES.FORM.ROUTERTEXT.DESC') }}
                  </router-link>
                </li>
              </div> -->
              <div
                class="float-right cursor-pointer"
                :disabled="clickCount >= 5"
                @mouseover="hovered = true"
                @mouseout="hovered = false"
                @click.prevent="addVariable"
              >
                {{ $t('WHATSAPP_TEMPLATES.FORM.ADDVARIABLE.DESC') }}
              </div>
            </div>
            <div class="body-content">
              <div v-if="showmessage" class="p-4">
                <!-- <h6>{{ $t('WHATSAPP_TEMPLATES.FORM.SAMPLEBODY.DESC') }}</h6>
                <p>
                  {{ $t('WHATSAPP_TEMPLATES.FORM.SAMPLEBODYCONTENT.DESC') }}
                </p> -->
                <!-- <div class="text-sm">
                  {{ $t('WHATSAPP_TEMPLATES.FORM.SAMPLEBODYCONTENT.DESC') }}
                </div> -->
                <div
                  v-for="index in clickCount"
                  :key="index"
                  class="flex p-3 gap-2"
                >
                  <label>
                    {{
                      $t(
                        `WHATSAPP_TEMPLATES.FORM.VARIABLES.SAMPLE${index}.LABEL`
                      )
                    }}
                  </label>
                  <input
                    v-model="body_text[index - 1]"
                    type="text"
                    class="w-[95%]"
                  />
                </div>
              </div>
            </div>
            <!-- FOR FOOTER BOX -->
            <input type="checkbox" v-model="footerCheck" />
            <label class="text-base font-bold pb-2">
              {{ $t('WHATSAPP_TEMPLATES.FORM.FOOTER.LABEL') }}
              <span class="text-xs pl-3">
                {{ $t('WHATSAPP_TEMPLATES.FORM.FOOTER.PLACEHOLDER') }}
              </span>
            </label>
            <!-- <P>
              {{ $t('WHATSAPP_TEMPLATES.FORM.INFO.LABEL') }}
            </P> -->
            <div class="mb-3" v-if="footerCheck">
              <input v-model="footer" type="text" @input="$v.footer.$touch" />
            </div>
            <!-- FOR BUTTON BOX -->
            <!-- <label class="text-base font-bold pb-2">
              {{ $t('WHATSAPP_TEMPLATES.FORM.BUTTON.LABEL') }}
            </label>
            <p>
              {{ $t('WHATSAPP_TEMPLATES.FORM.BUTTON_DESC.DESC') }}
            </p>
            <div class="button-card mb-4">
              <div class="p-3">
                <p class="text-base font-bold">
                  W3 recommended adding the marketing opt-out button.
                </p>
                <p class="font-bold">
                  {{ $t('WHATSAPP_TEMPLATES.FORM.BUTTONBODY.DESC') }}
                  <a>{{ $t('WHATSAPP_TEMPLATES.FORM.LEARNMORE.DESC') }} </a>
                </p>
              </div>
            </div>
            <div class="grid grid-cols-3">
              <select id="dropdown" v-model="buttonOption">
                <option value="none">
                  {{ $t('WHATSAPP_TEMPLATES.FORM.ACTIONBUTTON.SAMPLE1.LABEL') }}
                </option>
                <option value="ActionButton">
                  {{ $t('WHATSAPP_TEMPLATES.FORM.ACTIONBUTTON.SAMPLE2.LABEL') }}
                </option>
                <option value="QuickReply">
                  {{ $t('WHATSAPP_TEMPLATES.FORM.ACTIONBUTTON.SAMPLE3.LABEL') }}
                </option>
              </select>
            </div> -->
            <div v-if="buttonOption === 'ActionButton'">
              <div class="grid grid-cols-3 gap-3">
                <div class="align-middle flex flex-row gap-4">
                  <label for="checkWeb">
                    {{
                      $t('WHATSAPP_TEMPLATES.FORM.ACTIONBUTTON.SAMPLE4.LABEL')
                    }}
                  </label>
                  <input
                    id="id="
                    v-model="enableWebsite"
                    type="checkbox"
                    name="checkWeb"
                    class="mb-0"
                  />
                </div>
                <div class="w-[70%]">
                  <label for="buttonText">
                    {{
                      $t('WHATSAPP_TEMPLATES.FORM.ACTIONBUTTON.SAMPLE5.LABEL')
                    }}
                  </label>
                  <input
                    v-model="buttonText"
                    type="text"
                    :disabled="!enableWebsite"
                    @focus="isButtonFocused = true"
                    @blur="isButtonFocused = false"
                  />
                </div>
                <div class="flex flex-row gap-3">
                  <div class="w-[70%]">
                    <label for="websiteUrl">
                      {{
                        $t('WHATSAPP_TEMPLATES.FORM.ACTIONBUTTON.SAMPLE6.LABEL')
                      }}
                    </label>
                    <input
                      id="websiteUrl"
                      v-model="websiteUrl"
                      type="text"
                      :disabled="!enableWebsite"
                    />
                  </div>
                  <div>
                    <label for="dynamicUrl">
                      {{
                        $t('WHATSAPP_TEMPLATES.FORM.ACTIONBUTTON.SAMPLE7.LABEL')
                      }}
                    </label>
                    <input
                      id="dynamicUrl"
                      v-model="dynamicEnabled"
                      type="checkbox"
                      name="checkDynamic"
                      :disabled="!enableWebsite"
                      class="mb-0"
                      @click="addButtonVariable"
                    />
                  </div>
                </div>
              </div>
              <div v-if="dynamicEnabled" class="dynamic-check mt-2">
                <div class="p-4">
                  <p>
                    {{ $t('WHATSAPP_TEMPLATES.FORM.DYNAMIC.DESC') }}
                  </p>
                  <div class="text-base font-bold">
                    {{ $t('WHATSAPP_TEMPLATES.FORM.SAMPLEURL.DESC') }}
                  </div>
                  <div class="flex p-3 gap-2">
                    <label>
                      {{
                        $t(`WHATSAPP_TEMPLATES.FORM.VARIABLES.SAMPLE1.LABEL`)
                      }}
                    </label>
                    <input
                      v-model="dynamicValue"
                      type="text"
                      class="w-[95%]"
                      @input="updateDynamicValue"
                    />
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-3 gap-3">
                <div class="align-middle flex flex-row gap-4">
                  <label for="checkCart">
                    {{
                      $t('WHATSAPP_TEMPLATES.FORM.ACTIONBUTTON.SAMPLE8.LABEL')
                    }}
                  </label>
                  <input
                    id="id="
                    v-model="enableCart"
                    type="checkbox"
                    name="checkWeb"
                    class="mb-0"
                  />
                </div>
                <div class="w-[70%]">
                  <label for="buttonText">
                    {{
                      $t('WHATSAPP_TEMPLATES.FORM.ACTIONBUTTON.SAMPLE9.LABEL')
                    }}
                  </label>
                  <input
                    v-model="buttonText1"
                    type="text"
                    :disabled="!enableCart"
                    @focus="isButtonFocusedforNumber = true"
                    @blur="isButtonFocusedforNumber = false"
                  />
                </div>
                <div class="w-[70%]">
                  <label for="phoneNumber">
                    {{
                      $t('WHATSAPP_TEMPLATES.FORM.ACTIONBUTTON.SAMPLE10.LABEL')
                    }}
                  </label>
                  <input
                    id="phoneNumber"
                    v-model="phoneNumber"
                    type="text"
                    :disabled="!enableCart"
                    :class="{
                      'red-border': isButtonFocusedforNumber,
                    }"
                  />
                  <span
                    v-if="isInvalidPhoneNumber(phoneNumber)"
                    class="required-asterisk"
                    >{{ $t('WHATSAPP_TEMPLATES.FORM.MESSAGEERROR.DESC') }}</span
                  >
                </div>
              </div>
            </div>
            <div v-if="buttonOption === 'QuickReply'">
              <div class="grid-cols-2 grid">
                <div class="mb-3">
                  <label class="header" for="websiteUrl">
                    {{ $t('WHATSAPP_TEMPLATES.FORM.BUTTONCART.DESC') }}
                  </label>
                  <input
                    id="replyButton"
                    v-model="replyButtonValue"
                    type="text"
                  />
                </div>
              </div>
              <div class="">
                <div
                  v-for="(card, index) in navigationCards"
                  :key="index"
                  class="mb-3"
                >
                  <div class="grid-cols-2 grid">
                    <div>
                      <label>{{ card.label }}</label>
                      <input
                        :id="`card.id_${index}`"
                        v-model="card.value"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button
                class="text-gray-900 border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 ring-1 cursor-pointer text-woot-400"
                @click.prevent="addNavigationCard"
              >
                {{ $t('WHATSAPP_TEMPLATES.FORM.QUICKREPLYBUTTON.DESC') }}
              </button>
            </div>
          </div>
          <div v-if="uiFlags.isFetching" class="template--loader">
            <spinner size="large" color-scheme="primary" />
            <span class="text-white">Fetching Data...</span>
          </div>
        </div>
        <div class="flex items-center gap-1.5">
          <woot-submit-button
            :disabled="uiFlags.isUpdating"
            :button-text="submitButtonText"
            :loading="uiFlags.isUpdating"
          />
        </div>
      </div>
    </form>
  </div>
</template>
  
  <script>
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import { required } from 'vuelidate/lib/validators';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import WhatsappSelectorLogic from './WhatsappSelectorLogic';
import fetchSecretMixin from 'shared/mixins/fetchSecret';
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
import Spinner from 'shared/components/Spinner.vue';
import WhatsappForm from './WhatsappForm.vue';
export default {
  components: {
    WootSubmitButton,
    WootMessageEditor,
    WhatsappForm,
    Spinner,
  },
  mixins: [fetchSecretMixin, alertMixin],
  props: {
    onSubmit: {
      type: Function,
      default: () => {},
    },
    submitInProgress: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => {},
    },
    submitButtonText: {
      type: String,
      default: '',
    },
  },
  data() {
    const formData = this.formData || {};
    const {
      header = '',
      file = '',
      fileName = '',
      body = '',
      footer = '',
      buttonText = '',
      buttonText1 = '',
      websiteUrl = '',
      phoneNumber = '',
      replyButton = '',
    } = formData;

    return {
      format: 'text',
      header,
      header_text: [],
      headerCount: 0,
      showheader: false,
      file,
      fileName,
      body,
      body_text: [],
      footer,
      buttonOption: 'none',
      buttonText,
      buttonText1,
      websiteUrl,
      phoneNumber,
      replyButton,
      enableWebsite: false,
      enableCart: false,
      navigationCards: [],
      replyButtonValue: '',
      previousInputBoxData: '',
      clickCount: 0,
      finalbody: '',
      previousVariableCount: 0,
      isButtonFocused: false,
      isButtonFocusedforNumber: false,
      characterLimitError: '',
      maxCharacterLimit: 60,
      wordCount: body.length,
      wordLimitError: '',
      messageVisible: false,
      showmessage: false,
      hovered: false,
      previousHeaderCount: 0,
      previousInputHeaderData: '',
      dynamicEnabled: false,
      dynamicValue: '',
      dynamicInput: '',
      hours: new Date().getHours().toString().padStart(2, '0'),
      minutes: new Date().getMinutes().toString().padStart(2, '0'),
      restrictionHeader: false,
      restrictionBody: false,
      templateEditdata: {},
      headerCheck: false,
      footerCheck: false,
      formobjectdata: {},
      isDataReady: false,
      formdata: {
        selectorData: {},
        formData: {}, // Object to hold form data from WhatsappForm
      },
      header_handle: '',
      imagePreviewUrl: null,
      videoPreviewUrl: '',
      documentPreviewUrl: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
      currentUser: 'getCurrentUser',
      templateLists: 'whatsappTemplates/getTemplateFlags',
      uiFlags: 'whatsappTemplates/getUIFlags',
    }),

    isVideo(){
      return /\.(mp4|webm|ogg|mkv|mov|avi|flv|wmv|m4v)(\?.*)?$/i.test(this.header_handle);
    },

    isImage(){
      return /\.(jpg|jpeg|png|gif|bmp|svg|webp)(\?.*)?$/i.test(this.header_handle[0]);
    },

    isDocument(){
      return /\.pdf(\?.*)?$/i.test(this.header_handle[0]);
    }

  },
  mounted() {
    this.viewCategory();
    // console.log('This Template List', this.templateLists);
    // const data=this.$store.getters['whatsappTemplates/getTemplateFlags'];
    // console.log("Data is::::",data);
  },
  validations: {
    body: {
      required,
    },
    footer: {
      required,
    },
  },
  watch: {
    ...WhatsappSelectorLogic.watch,
    $route(to, from) {
      // Check if the route has the `templateId` parameter
      if (to.params.templateId) {
        console.log('Template ID found:', to.params.templateId);
        this.viewCategory();
      } else {
        console.log('No template ID in route');
      }
    },
  },
  methods: {
    ...WhatsappSelectorLogic.methods,
    async onFileChange(event) {
      const file = event.target.files[0];
      if (!file) return;
      this.isLoading = true
      try {
        let maxFileSize = 15 * 1024 * 1024; // 15MB

        if (file.type.startsWith('image/') || file.type === 'application/pdf' ) {
          maxFileSize = 5 * 1024 * 1024; // Image max size: 5MB
        }

        if (file.size > maxFileSize) {
          this.isLoading = false
          file.type.startsWith('image/') || file.type === 'application/pdf' ? this.showAlert('The file size exceeds the 5MB limit. Please upload a smaller file.') : this.showAlert('The video size exceeds the 15MB limit. Please upload a smaller file.') ;
          throw new Error('The file size exceeds the limit. Please upload a smaller file.');
        }

        this.fileName = file.name;

        const config = {
          file_type: file.type,
          file_name: file.name,
          file_length: file.size,
        };

        const formData = new FormData();
        formData.append('config', JSON.stringify(config)); // Append the config as a JSON string
        formData.append('file', file); // Append the file itself
        formData.append('account_id', this.currentAccountId);

        const response = await fetch('/api/v3/whatsapp/templates/get_upload_header', {
          method: 'POST',
          headers: {
            "api-access-token": this.currentUser.access_token, // Include the access token
            // Do NOT set Content-Type; let the browser handle it
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const uploadHeader = await response.json();
        // You can handle the uploadHeader as needed
        this.header_handle = uploadHeader.h
        this.isLoading = false
        if (file.type.startsWith('image/')) {
          this.imagePreviewUrl = URL.createObjectURL(file);
          this.videoPreviewUrl = null; 
          this.documentPreviewUrl = null; 
        } else if (file.type.startsWith('video/')) {
          this.videoPreviewUrl = URL.createObjectURL(file);
          this.imagePreviewUrl = null; 
          this.documentPreviewUrl = null; 
        } else if (file.type === 'application/pdf') {
          this.documentPreviewUrl = URL.createObjectURL(file);
          this.imagePreviewUrl = null; 
          this.videoPreviewUrl = null; 
        }
      } catch (error) {
        console.error('File upload error:', error);
      }
    },    

    async viewCategory() {
      this.isDataReady = false;
      const id = this.templateId();
      const keys = await this.getKeys();
      const data = await this.$store.dispatch('whatsappTemplates/ViewForm', {
        id: id,
        keys: keys,
      });
      this.templateEditdata = this.templateLists || data;
      console.log(
        'My Template data',
        this.templateEditdata.name,
        this.templateEditdata
      );
      const headerComponent = this.templateEditdata.components.find(
        component => component.type === 'HEADER'
      );
      if (headerComponent && headerComponent.text) {
        this.headerCheck = true;
        const modifiedText = headerComponent.text.replace(/\n\n/g, ' ');
        this.header = modifiedText;
        if (headerComponent.example && headerComponent.example.header_text) {
          this.showheader = true;
          this.headerCount = headerComponent.example.header_text.length;
          this.header_text = headerComponent.example.header_text || [];
        } else {
          this.header_text = [];
          this.showheader = false;
        }
      }else if(headerComponent && headerComponent.example.header_handle){
        this.headerCheck = true;
        this.header_handle = headerComponent.example.header_handle || [];
      }
      else {
        this.showheader = false;
        this.body = '';
        this.body_text = [];
      }

      const bodyComponent = this.templateEditdata.components.find(
        component => component.type === 'BODY'
      );

      if (bodyComponent) {
        const modifiedText = bodyComponent.text.replace(/\n\n/g, ' ');
        this.body = modifiedText;
        if (bodyComponent.example && bodyComponent.example.body_text) {
          this.showmessage = true;
          this.clickCount = bodyComponent.example.body_text[0].length;
          this.body_text = bodyComponent.example.body_text[0] || [];
        } else {
          this.body_text = [];
          this.showmessage = false;
        }
      } else {
        this.showmessage = false;
        this.body = '';
        this.body_text = [];
      }

      const footerComponent = this.templateEditdata.components.find(
        component => component.type === 'FOOTER'
      );

      if (footerComponent) {
        this.footerCheck = true;
        const modifiedText = footerComponent.text.replace(/\n\n/g, ' ');
        this.footer = modifiedText;
      } else {
        this.footer = '';
        this.footerCheck = false;
      }
      console.log('My Form Object', this.formobjectdata);
      this.formobjectdata = {
        locale: 'en',
        name: this.templateEditdata.name,
        categoryOption: this.templateEditdata.category,
      };
      this.isDataReady = true;
      console.log('My updated Form Object', this.formobjectdata);
    },
    templateId() {
      console.log('Template ID', this.$route.params.templateId);
      return this.$route.params.templateId;
    },
    inboxId() {
      return this.$route.params.inboxId;
    },

    async handleSubmit() {
      // try {
      // Fetch inbox ID and authentication keys
      const inboxId = this.templateId();
      const keys = await this.getKeys();

      console.log('Inbox ID:', inboxId);
      console.log('Keys:', keys);

      // Prepare the components array based on available data
      const components = [];
      // Check for HEADER data
      if (this.header && this.format && this.headerCheck) {
        let HeaderComponent = {
          type: 'HEADER',
          format: this.format.toUpperCase(), // Convert to uppercase if needed
          text: this.header,
        };
        if (this.header_text && this.header_text.length > 0) {
          HeaderComponent.example = {
            header_text: this.header_text, // Wrap body_text in an array
          };
        }
        }else if (this.format && this.headerCheck && this.format === 'video' && this.header_handle && this.header_handle.length > 0) {
          let HeaderComponent = {
          type: 'HEADER',
          format: this.format.toUpperCase(), // Convert to uppercase if needed
          };
          HeaderComponent.example = {
            header_handle: this.header_handle, // Wrap body_text in an array
          };
        components.push(HeaderComponent);
      }

  
      if (this.body) {
        let cleanedBody = this.body.replace(/\\+/g, '');
        let BodyComponent = {
          type: 'BODY',
          text: cleanedBody,
        };
        if (this.body_text && this.body_text.length > 0) {
          BodyComponent.example = {
            body_text: [this.body_text], // Wrap body_text in an array
          };
        }
        components.push(BodyComponent);
      }

      // Check for FOOTER data
      if (this.footer && this.footerCheck) {
        components.push({
          type: 'FOOTER',
          text: this.footer,
        });
      }

      // Prepare the data to be sent
      const dataToSend = {
        // Name of the template
        // Assuming templateName is a data property; replace with default if not provided

        // Components array
        components: components,
        ...this.formdata.formData

        // Language // You can replace this with a dynamic value if needed
      };

      // Set the headers for the request
      console.log('Data To Send', dataToSend);

      const response = await this.$store.dispatch(
        'whatsappTemplates/EditTemplate',
        {
          inboxId: inboxId,
          keys: keys,
          dataToSend: dataToSend,
        }
      );
      if (response?.data?.success) {
        this.showAlert('Edit Template Successfully');
      } else{
        if(response?.error?.error?.error_user_msg){
          this.showAlert(`${response?.error?.error?.error_user_msg}`)
        }else{
          this.showAlert('failed to edit template. please try again later')
        }
      }
    },
    updateFormData(data) {
      // Handle data from WhatsappForm
      this.formdata.formData = data;
      console.log('Form Data:', data);
    },
  },
};
</script>
  
  <style scoped>
input {
  @apply mb-0;
}
.upload-image-btn {
  position: relative;
  bottom: 10px;
}
.upload-image-btn input {
  position: absolute;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.red-border {
  border: 1px solid red;
}
.required-asterisk {
  color: red;
  margin-left: 5px;
  font-size: 11px;
}
.button-card {
  border: 1px solid #d1cece;
  border-left: 4px solid green;
  border-radius: 5px;
}
.warning-message {
  background-color: khaki;
}
.body-content,
.dynamic-check {
  background-color: #e9e9e9;
  margin-bottom: 5px;
  border-radius: 5px;
}
.template--loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  pointer-events: none;
  cursor: not-allowed;
}
.template--loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  pointer-events: none;
  cursor: not-allowed;
}

</style>
  