<template>
    <div class="wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%]">
        <div v-if="successMessage" class="overlay" v-show="showSuccessMessage">
            <div class="success-popup">
                <span class="close-button" @click="closeSuccessMessage">&times;</span>
                {{ successMessage }}
            </div>
        </div>

        <div class="header-container">
            <h4 class="text-lg font-medium text-black-900 dark:text-slate-200">
                {{ $t('GENERAL_SETTINGS.DOMESTIC_CALLS.INBOX_TITLE') }}
            </h4>
        </div>

        <form @submit.prevent="createChannel">
            <div class="form-fields">
                <!-- Name Input Field -->
                <div class="form-group">
                    <label :class="{ error: $v.name.$error }">
                        {{ $t('GENERAL_SETTINGS.DOMESTIC_CALLS.NAME') }}
                        <input class="inbox_name" type="text" v-model="name" @blur="$v.name.$touch" placeholder="Enter inbox name here .." />
                        <span v-if="$v.name.$error" class="message">
                            {{ $t('GENERAL_SETTINGS.DOMESTIC_CALLS.ERROR_NAME') }}
                        </span>
                    </label>
                </div>
            </div>

            <button type="submit">
                {{ $t('GENERAL_SETTINGS.DOMESTIC_CALLS.SUBMIT') }}
            </button>
        </form>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import router from '../../../../index';

export default {
    data() {
        return {
            name: '', // Only the inbox name is used
            successMessage: '',
            showSuccessMessage: true,
            account_id: this.accountId,
        };
    },
    computed: {
        ...mapGetters({
            accountId: 'getCurrentAccountId',
        }),
    },
    validations: {
        name: {
            required,
        },
    },
    methods: {
        async createChannel() {
            // Trigger validation
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.errorMessage = 'Please fill in all required fields.';
                return;
            }

            try {
                // Send only the name to create the JioEva channel
                const jioEvaChannel = await this.$store.dispatch('inboxes/createJioEvaChannel', {
                    jio_eva: {
                        type: 'jio_eva',
                        name: this.name,
                        accountId: this.accountId,
                    },
                });

                console.log('this is jio eva channel :::', jioEvaChannel);

                // Navigate to the agents setup page upon successful channel creation
                router.replace({
                    name: 'settings_inboxes_add_agents',
                    params: {
                        page: 'new',
                        inbox_id: jioEvaChannel.id,
                    },
                });
            } catch (error) {
                console.error('Error creating JioEva channel or submitting data:', error);
                this.errorMessage = this.$t('INBOX_MGMT.ADD.JIO_EVA_CHANNEL.API.ERROR_MESSAGE');
            }
        },
        closeSuccessMessage() {
            this.showSuccessMessage = false;
            this.successMessage = '';
        },
    },
};
</script>
  
<style scoped>
label {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 16px;
}

.inbox_name, select {
    width: 70%;
}

select.error {
    border-color: red;
}

.message {
    color: red;
    font-size: 0.875rem;
}

button {
    margin-top: 1rem;
    cursor: pointer;
    background-color: hsl(209 100% 60.6% / 1);
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    border: none;
    font-size: 1rem;
    width: 20%;
}

.overlay {
    position: fixed;
    margin: auto;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
    width: 250px;
}

.success-popup {
    background-color: #44ce4b;
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: relative;
}

.close-button {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
}
</style>